import React from 'react'
import Image from "../image/image";


const PodcastListItem = ({data, setActive, activeVideo, play}) => {

  const podcastClicked = () => {
    setActive({ id: data.videoId,
                title: data.title,
                description: data.description,
                play: true })
  }

  const podcastDescription = (desc) => {
    //trim the string to the maximum length
    let trimmedString = desc.substr(0, 155);

    //re-trim if we are in the middle of a word
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));

    return trimmedString+'...';
  }

  return (
    <li className={`Podcasts__item d-flex mb-4${activeVideo === data.videoId && play ? ' active' : ''}`}>
      <div className="Podcasts__item-img">
        <div><img src={`https://img.youtube.com/vi/${data.videoId}/0.jpg`} /></div>
      </div>
      <div className="Podcasts__item-content">
        <p><b>Episode #{data.episode}</b> {data.duration}</p>
        <h4>{data.title}</h4>
        <p>{podcastDescription(data.description)}</p>
        <button onClick={podcastClicked}>
          {activeVideo === data.videoId && play ?
            <Image image_url="icons/icon-v-pause.svg"/>
            : <Image image_url="icons/icon-v-play.svg"/>
          }
        </button>
      </div>
    </li>
  )
}

export default PodcastListItem
