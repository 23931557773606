import React, {useState} from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo/seo'
import PodcastListItem from 'components/podcasts/podcast-list-item'
import ActivePodcast from 'components/podcasts/active-podcast'
import GetInTouch from 'components/get-in-touch/get-in-touch'
import Image from 'components/image/image'
import '../components/podcasts/podcasts.sass'


const Podcasts = ({ data }) => {
  const d = data.podcastsYaml;
  const [activeVideo, setActiveVideo] = useState({  id: d.podcasts[0].videoId,
                                                              title: d.podcasts[0].title,
                                                              description: d.podcasts[0].description});

  const setActive = (videoData) => {
    setActiveVideo(videoData)
  }

  const podcastList = () => {
    return d.podcasts.map((podcast, i) => {
      return (
        <PodcastListItem
          key={i}
          data={podcast}
          setActive={setActive}
          activeVideo={activeVideo.id}
          play={activeVideo.play ? true : false} />
      )
    })
  }

  return (
    <Layout bodyClass="k-reverse-header">
      <SEO
        title="The Machine Learning Podcast | Kortical"
        keywords={['Podcast Page', 'The Machine Learning Podcast', 'AI Podcast', 'AI', 'Machine Learning', 'AutoML', 'AI Consulting', 'ML', 'Artificial Intelligence']} />
      <div className="Podcasts">
        <section className="Podcasts__header k-bg--grey">
          <div className="container-fluid">
            <Image className="Podcasts__header-img" image_url={d.hero}/>
            <div className="d-flex k-row">
              <div className="col-12 px-4">
                <h1 className="h1--new"><span>The</span><br/><span>Machine Learning</span><br/><span>Podcast</span></h1>
                <p className="mb-5"><span>{d.subtitle}</span></p>
              </div>
            </div>
          </div>
        </section>
        <section className="Podcasts__intro">
          <div className="container-fluid container-fluid--small k-section">
            <div className="d-flex k-row">
              <div className="col-12 px-4">
                <h2 className="mb-4">Build the AI Future</h2>
                <p className="p-lg">We love Artificial Intelligence (AI), and we believe that AI is a force for good that can benefit everyone. The AI future has already started and is changing how work is delivered in many industries.</p>
                <p className="p-lg">“~50% of all current work activities are technically automatable using currently demonstrated technologies.” - Mckinsey</p>
                <p className="p-lg">However, the majority of AI projects fail - about 85% according to Gartner yet the outpour of investments in AI technologies remains strong.</p>
                <p className="p-lg">This podcast series shares our learnings and experiences in delivering AI-driven digital transformation for Fortune500 companies like the NHS, Deloitte, Ford and start-ups like Charlotte Tilbury and flydocs.</p>
                <p className="p-lg">Our goal with this Machine Learning series is to provide insights into business processes and practical tips from real-world examples of AI that showcase how to successfully deliver AI/Machine learning solutions and build towards the AI future.</p>
              </div>
            </div>
          </div>
        </section>
        <ActivePodcast videoId={activeVideo.id} title={activeVideo.title} description={activeVideo.description} play={activeVideo.play ? true : false} />
        <section className="Podcasts__intro">
          <div className="container-fluid container-fluid--small k-section">
            <div className="d-flex k-row">
              <div className="col-12 px-4">
                {podcastList()}
              </div>
            </div>
          </div>
        </section>
      </div>
      <GetInTouch/>
    </Layout>
  )
}

export default Podcasts

export const pageQuery = graphql`
  query {
    podcastsYaml {
      title
      subtitle
      hero
      podcasts {
        videoId
        episode
        duration
        title
        description
      }
    }
  }
`