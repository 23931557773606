import React from 'react'
import Image from "../image/image";
import {TwitterShareButton, FacebookShareButton} from "react-share";


const ActivePodcast = ({videoId, title, description, play}) => {
  const copyLink = () => {
    const share_popup = document.querySelector(".Podcasts__share-popup");
    navigator.clipboard.writeText(`https://youtu.be/${videoId}`);
    share_popup.classList.add("active");
    setTimeout(function(){share_popup.classList.remove("active")}, 3000);
  }

  return (
    <section>
      <div className="container-fluid container-fluid--small">
        <div className="d-flex k-row">
          <div className="col-12 px-4">
            <h2 className="mb-4">Episodes</h2>
            <div className="Podcasts__active-wrapper mb-3">
              <iframe className="Podcasts__active-podcast"
                      src={play ? `https://www.youtube.com/embed/${videoId}?cc_load_policy=1&amp;hl=en&amp;cc_lang_pref=en&amp;rel=0&amp;autoplay=1&amp;mute=1` : `https://www.youtube.com/embed/${videoId}`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen>
              </iframe>
            </div>
            <div className="Podcasts__share">
              <h4>Share</h4>
              <div className="d-flex">
                <div className="Podcasts__share-popup">Video link copied to clipboard</div>
                <button className="Podcasts__share-icon Podcasts__share-link" onClick={copyLink}>
                  <Image image_url="icons/icon-link.svg" />
                </button>
                <FacebookShareButton
                  url={`https://youtu.be/${videoId}`}
                  hashtag={"#TheMachineLearningPodcast"}
                  description={description}
                  className="Podcasts__share-icon"
                >
                  <Image image_url="icons/icon-facebook-dark.svg"/>
                </FacebookShareButton>
                <TwitterShareButton
                  title={title}
                  url={`https://youtu.be/${videoId}`}
                  hashtags={["TheMachineLearningPodcast", "Kortical"]}
                  className="Podcasts__share-icon"
                >
                  <Image image_url="icons/icon-twitter-dark.svg"/>
                </TwitterShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ActivePodcast
